import React, { useEffect, useState } from 'react';
import { init } from './Web3Client';
import Web3 from 'web3'
import routerAbi from "./routerAbi.json";
import tokenAbi from './abi.json';
import usdcAbi from './usdcAbi.json';
import "./App.css"

function App() {
  const [tokenOne, setTokenOne] = useState();
  const [tokenTwo, setTokenTwo] = useState();
  const [currency, setCurrency] = useState(null)
  const [tokenA, setTOkenA] = useState(0);
  const [tokenB, setTOkenB] = useState();
  let myContract, routerContract, usdcContract;
  let worldOneAddress = '0x330c6607663a752b639d505914ff8bd21a3fdd9e';
  let USDCAddress = '0xAe34f599C53eDC6B703dd3d91183e17DaaF0790a';
  let routerAddress = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

  const currencyFunc = async (e) => {
    e.preventDefault()
    var selectedCurrency = document.getElementById('currencyDropdown');
    var statusValue = selectedCurrency.options[selectedCurrency.selectedIndex].value;
    setCurrency(statusValue);
  }


  const HandleContract = async () => {
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    const networkId = await web3.eth.net.getId();
    console.log(networkId)
    myContract = new web3.eth.Contract(tokenAbi, worldOneAddress);
    routerContract = new web3.eth.Contract(routerAbi, routerAddress);
    usdcContract = new web3.eth.Contract(usdcAbi, USDCAddress)

  }

  const getAmounts = async () => {
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    routerContract = new web3.eth.Contract(routerAbi, routerAddress);
    let amount;
    console.log('multiplied',tokenA*1000000000)
    if (currency==0){
    amount = await routerContract.methods.getAmountsOut(tokenA*1000000000, [USDCAddress, worldOneAddress]).call()
    }
    else {
    amount = await routerContract.methods.getAmountsOut(tokenA*1000000000, [worldOneAddress, USDCAddress]).call()
    }
    setTOkenB(amount[1]/1000000000)
    console.log(amount)

  };

  const HandleSwap = async (e) => {
    e.preventDefault()
    let amount;
    if (currency==0){
      amount = await routerContract.methods.swapExactTokensForTokens(tokenA*1000000000, 1000000, [USDCAddress, worldOneAddress], window.ethereum.selectedAddress, 16244252424).send({ from: window.ethereum.selectedAddress })
    }
    else {
      amount = await routerContract.methods.swapExactTokensForTokens(tokenA*1000000000, 1000000, [worldOneAddress, USDCAddress], window.ethereum.selectedAddress, 16244252424).send({ from: window.ethereum.selectedAddress })
    }
    console.log(amount)
  }



  useEffect(() => {
    init();
    HandleContract();
  });
  useEffect(() => {
    if (tokenA) {
      getAmounts()
    }
  }, [tokenA])
  return (
    <div className="wrapper">
      <div className="swap-section" style={{ marginTop: "40px" }}>
        <div className="swap-header">
          <h2>DeFi Swap</h2>
          <p>The Best Place to Swap & Farm DeFi Coins</p>
        </div>
        <form action="" className="swap-form" style={{ marginTop: "20px" }}>
          <div className="coin-container">
            <label htmlFor="fname">From</label>
            <div className="input-wrapper">
              <input
                type="number"
                id="tokenA"
                name="tokenA"
                onChange={(event) => { setTOkenA((event.target.value)) }}
                value={tokenA}
              />
              <select id='currencyDropdown' onChange={currencyFunc} required>
                <option value={'1'}>WorldOne</option>
                <option value={'0'}>USDC</option>
              </select>
            </div>
          </div>
          <svg
            version="1.1"
            id="Layer_1"
            href="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 24.1 37.8"
            style={{ enableBackground: "new 0 0 24.1 37.8" }} >
            <path id="XMLID_27_"
              d="M9.8,1.7L10,32l-6.6-5.7c-0.8-0.7-2.1-0.7-2.8,0C0.2,26.7,0,27.1,0,27.5c0,0.4,0.2,0.9,0.6,1.2l10.1,8.6
c0.8,0.7,2.1,0.7,2.8,0l9.9-8.6c0.8-0.7,0.8-1.8,0-2.4c-0.8-0.7-2.1-0.7-2.8,0L14,32L13.8,1.7c0-1-0.9-1.7-2-1.7S9.8,0.8,9.8,1.7z" />
          </svg>
          <div className="coin-container">
            <label htmlFor="fname">From</label>
            <div className="input-wrapper">
              <input
                type="number"
                id="tokenB"
                name="tokenB"
                value={tokenB}
              />
              <select id="currencyDropdown" disabled>
                {
                  currency === '0' ? <option value={1} selected>WorldOne</option> : <option value={0} selected>USDC</option>
                }
              </select>
            </div>
          </div>
          <button  onClick={HandleSwap}>Swap</button>
        </form>
      </div>
    </div>);
}
export default App;